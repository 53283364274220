#intercom-container .intercom-messenger-frame {
left: 20px !important;
}

#intercom-container .intercom-launcher-frame {
left: 20px !important;
}

#intercom-container .intercom-launcher-discovery-frame {
left: 20px !important;
}

.border-box {
	box-sizing: border-box;
}

.field-container ul.ui-autocomplete {
	background-color: theme(colors.white);
	width: 350px;
	box-shadow:         1px 2px 4px rgba(0, 0, 0, 0.5);
	list-style-type: none;
    border-radius:10px 10px 0px 0px;

}

ul.ui-autocomplete {
	background-color: theme(colors.white);
	border: 1px solid theme(colors.neutral.300);
	width: 500px;
	border-radius: 4px;
	box-shadow: 0 4px 5px rgba(0, 0, 0, 0.15);
	list-style-type: none;
	max-height: 500px;
	overflow: auto;
	z-index: 999999 !important;
}

#user-select-search {
	font-size: theme(fontSize.base); width: 250px; padding: 3px; padding-left: 30px;
    transition: none 0s ease 0s;
    background: url("search_icon.png") no-repeat scroll 10px center theme(colors.white);
}

.usi-item {
	border-top: 1px solid theme(colors.neutral.100);
	border-bottom: 1px solid white;
	border-right: 1px solid white;
	border-left: 1px solid white;
}

.usi-item-even {
	background-color: theme(colors.neutral.100);
}

.usi-item-odd {
	background-color: theme(colors.neutral.50);
}

.usi-item:hover {
	background: theme(colors.sky.100) !important;
	border-top: 1px solid white;
}

.usi-avatar {
	float: left; padding: 15px; padding-top: 10px; padding-bottom: 10px;
}

.usi-name {
	float: left; margin-top: 12px;
}

.usi-name .name {
	color: theme(colors.neutral.700);
	font-weight: bold;
	font-size: theme(fontSize.base);
	margin-top: 0px;
	margin-bottom: 0px;
}

.usi-name .username {
	color: gray;
	margin-top: 0px;
}

.gsi-left {
	float: left; padding: 10px; font-size: theme(fontSize.2xs);
	width: 80px;
	text-align: right;
	color: theme(colors.neutral.600);
}

.gsi-right {
	float: left; padding: 10px;
	width: 230px;
	border-left: 1px solid theme(colors.neutral.200);
	text-shadow: 0px 1px 2px theme(colors.white); /* Old browsers */
	/* IE9 SVG, needs conditional override of 'filter' to 'none' */ /* FF3.6+ */ /* Chrome,Safari4+ */ /* Chrome10+,Safari5.1+ */ /* Opera 11.10+ */ /* IE10+ */
	background: linear-gradient(to right,  theme(colors.white) 0%,theme(colors.neutral.100) 47%,theme(colors.neutral.200) 100%); /* W3C */
	/* IE6-8 */
    border-radius:0px 10px 10px 0px;


}

.gsi-right p.title {
	font-weight: bold;
}

.gsi-right p.desc {
	font-size: theme(fontSize.2xs);
	color: theme(colors.neutral.600);
	font-style: italic;
}

.gsi-right p {
	margin-bottom: 0px;
	color: theme(colors.neutral.700);
}

.gsi {
	border-bottom: none; padding-top: 5px; padding-bottom: 5px; font-size: theme(fontSize.sm); padding-left: 15px; padding-right: 15px; color: theme(colors.neutral.600);
    cursor: pointer;
}

.gsi-n {
    margin-bottom: 0px; font-size: theme(fontSize.base); color: theme(colors.black);
}

.gsi-d {
	margin-bottom: 0px; font-size: theme(fontSize.xs); color: theme(colors.neutral.900); font-weight: bold;
}

.gsi-p {
  margin-bottom: 0px; font-size: theme(fontSize.2xs); color: theme(colors.neutral.400); font-style: italic;
}

.gsi:hover, .gsi-right:hover, .gsi:hover p,
.ui-state-hover .gsi, .ui-state-hover .gsi-right, .ui-state-hover .gsi p,
.ui-state-active .gsi, .ui-state-active .gsi-right, .ui-state-active .gsi p{
  text-decoration: none !important;
  background-color: theme(colors.neutral.100) !important;
}



.ui-autocomplete .ui-menu-item .ui-state-active {
  border: none;
}



.gsi-bordertop {
	margin-top: 0;
}

.video-list-item {
	margin-top: 0px; padding: 10px; padding-left: 15px;
	cursor: pointer;
}
/*
.video-list-item:hover {
	background: theme(colors.sky.100) !important;
}
*/
.video-list-item .time {
	position: absolute; top: 42px; right: 5px; background-image: url("transparent_black.png"); color: white; font-weight: normal; padding: 0px; padding-right: 2px; padding-left: 2px; font-size: theme(fontSize.2xs);
}

.video-list-item .video-list-item-right {
	float: left; margin-left: 20px;
}

.video-list-item .video-list-item-right p.title {
	margin-bottom: 0px; color: theme(colors.neutral.700); font-size: theme(fontSize.xs); font-weight: bold;
}

.video-list-item .video-list-item-right p.question {
	margin-bottom: 0px;
}

.video-list-item .video-list-item-right p.question span {
	font-size: theme(fontSize.2xs);
}

.video-list-item .video-list-item-right p.desc {
	margin-bottom: 0px; margin-top: 8px; color: theme(colors.neutral.500); font-size: theme(fontSize.2xs);
}

hr.video-list-divider {
	margin-top: 0px; margin-bottom: 0px; border-top: 1px solid theme(colors.neutral.200);
}

.video-list-item:hover {
	background-color: theme(colors.neutral.100);
}

.light-white-gradient {
  @apply bg-neutral-50;
}

.video-list-item-active, .video-list-item-active:hover, .light-gray-gradient {
	border-left: 1px solid white;
	cursor: default !important;
	background-color: theme(colors.neutral.100);
  background-image: linear-gradient(to top, rgb(230,230,230) 14%, rgb(245,245,245) 57%, rgb(245,245,245) 79%);
}

#interview-videos {
	width: 300px; min-width: 300px; padding: 0px; background-color: theme(colors.neutral.100); border-left: 1px solid theme(colors.neutral.300);
    border-radius:0px 0px 10px 0px;

}

.add-video-panel { /* Old browsers */
	/* IE9 SVG, needs conditional override of 'filter' to 'none' */ /* FF3.6+ */ /* Chrome,Safari4+ */ /* Chrome10+,Safari5.1+ */ /* Opera 11.10+ */ /* IE10+ */
	background: linear-gradient(to bottom,  theme(colors.neutral.200) 0%,theme(colors.neutral.100) 51%,theme(colors.neutral.200) 100%); /* W3C */
	/* IE6-8 */
}

.view-type-toggle {
	cursor: pointer;
	background: url("view_type.png") no-repeat scroll left top transparent;
	width: 20px; height: 16px;
	float: right;
	margin-left: 7px;
}

.view-type-toggle-large {
	background-position: 0px -22px;
}

.view-type-toggle-large:hover {
	background-position: 0px 0px;
}

.view-type-toggle-list {
	background-position: -28px -22px;
}

.view-type-toggle-list:hover {
	background-position: -28px 0px;
}

body .modal-large {
/*    //new custom width
*/    width: 750px;
/*    //must be half of the width, minus scrollbar on the left (30px)
*/    margin: -345px 0 0 -375px;
}

#user-list {
	margin-left: -50px; margin-top: 30px;
}

#user-list div.user-list-item {
	float: left; text-align: center; margin-left: 60px; margin-bottom: 30px;
}

.laquo-link, .laquo-link:hover {
	text-decoration: none;
}

.laquo-link div {
	border: 1px solid theme(colors.orange.50);
}

.laquo-link div:hover {
	border: 1px solid theme(colors.neutral.300);
}

.box-content-menu-divider {
	float: left; padding-top: 12px; padding-bottom: 12px; border-left: 1px solid theme(colors.neutral.200); width: 0px; height: 20px;
}

.box-content-menu {
	padding-left: 20px; padding-right: 0px; border-top: 1px solid white; border-bottom: 1px solid theme(colors.neutral.200);
}

.box-content-button {
	float: left; padding-top: 12px; padding-bottom: 12px; padding-right: 20px; padding-left: 20px; text-shadow: 0px 1px 0px rgba(255,255,255,0.5);
	border-bottom: 1px solid white;
  @apply text-xs text-neutral-600 font-bold;
}
.box-content-button:hover {
	@apply text-neutral-900 no-underline;
}
.box-content-menu:hover .box-content-button {
}


.box-content-item {
	float: left; padding-top: 12px; padding-bottom: 12px; padding-right: 20px; padding-left: 20px;
  @apply text-sm;
}

.box-content-item a {
	text-shadow: 0px 1px 0px rgba(255,255,255,0.5);
  @apply text-sky-800;
}


/* Content Dialogs */

.ct-dialog {
	margin: auto;
}

.ct-dialog-small {
	width: 400px;
}

.ct-dialog .ct-notice {
	margin: auto; margin-bottom: 20px;
}

.ct-dialog .ct-notice-error {
	color: theme(colors.red.700);
}

.ct-dialog .ct-title {
	font-size: theme(fontSize.lg);
  @apply mb-2;
}

.ct-dialog .ct-content {
	padding: 20px; padding-bottom: 30px; text-align: center;
}

.ct-dialog h6.ct-info-text {
	margin-bottom: 10px; margin-top: -10px;
}

.ct-dialog .ct-company-name {
  @apply text-2xl font-bold mb-8;
}

.ct-dialog .ct-dialog-content {
	text-align: left; border-left: 1px solid white; margin-top: 20px;
}

.ct-dialog .ct-image {
	float: left; margin-left: 20px; margin-top: 20px;
}

.ct-dialog .ct-right-content {
	float: left; margin-left: 25px; margin-top: 15px; width: 300px;
}

.ct-dialog .ct-description {
	font-weight: bold; margin-top: 10px; margin-bottom: 20px;
}

.ct-dialog .ct-required {
  color: theme(colors.red.800); font-size: theme(fontSize.lg);
}

.ct-dialog .ct-input {
	/* float: left;
  margin-left: 10px; */
  @apply text-sm my-2;

  input, select {
    @apply w-full;
  }
}

.ct-dialog .ct-input-submit {
	margin-top: 20px; margin-bottom: 26px;
}

.ct-dialog .ct-help-text {
	margin-top: 10px; color: theme(colors.neutral.800);
}

.ct-dialog .ct-divider {
	border-top: 1px dashed theme(colors.neutral.200); margin-top: 15px; padding-top: 25px; height: 1px; width: 100%;
}

.white-infobox {
	margin-bottom: 40px; background-color: white; padding: 12px !important; border: 2px solid white;
}

.white-infobox-icon {
	float: left; margin-top: 5px;
}

.white-infobox-icon span {
	font-size: 24px; margin-left: 5px; color: green;
}




/* FORM */

.form-step {
	float: left; width: 12%; text-align: center; margin-left: 5px; padding: 1px;
}

.form-step .description {
	margin: auto; font-size: theme(fontSize.sm); text-align: center; margin-top: 5px; color: theme(colors.neutral.500);
}

.form-step-enable {
	float: left; margin-left: 5px; width: 20%;  text-align: center;
}

.form-step-enable div {
	margin: auto; background-color: theme(colors.yellow.300); border-top: 1px solid theme(colors.yellow.100); color: theme(colors.yellow.950); width: 120px; text-align: center; padding: 3px; font-size: theme(fontSize.xs); font-weight: normal;  padding-top: 10px; height: 20px;
}

.form-step-container {
	margin-top: 20px; padding-top: 20px; padding-bottom: 20px; border-top: 1px solid theme(colors.neutral.200); font-size: theme(fontSize.sm);
}

.form-step-container .field {
	margin-top: 10px;
}

.form-step-container .field .label {
	float: left; font-size: theme(fontSize.sm); margin-top: 6px; font-weight: normal; width: 140px; text-align: right;
}

.form-step-container .field .input {
	float: left; margin-left: 50px;
}

.form-step-container .field .input {
	font-size: theme(fontSize.xs); line-height: 26px;
}

.form-step-container .field .input input[type=text], .form-step-container .field .input input[type=password] {
	font-size: theme(fontSize.sm); height: 25px; width: 250px;
}

.form-step-container .field .links {
	float: left; margin-left: 20px; margin-top: 10px;
}

.form-step-container .margin-top {
	margin-top: 30px;
}

/* Sidebar */

.sidebar-menu-item .dropdown-toggle {
	color: theme(colors.neutral.500);
}

.sidebar-menu-item .dropdown-toggle:hover {
	text-decoration: none;
}

.sidebar-menu-item {
/*	padding: 10px; margin: 0px; padding-top: 7px; padding-bottom: 7px; float: left;
	text-align: center;
	margin-top: 10px;
	margin-left: 20px;*/
	float: left;

	text-align: center;
	border-bottom: 2px solid theme(colors.neutral.300);
	padding-top: 11px;
	padding-bottom: 11px;
	color: theme(colors.neutral.500);
	font-weight: bold;
	text-shadow: 0 1px 0 white;
}

.sidebar-menu-item-padded {
	padding-left: 30px;
	padding-right: 30px;
}

.sidebar-menu-item-process-steps {
	width: 158px;
}

.sidebar-menu-item .description {
	color: gray; margin-top: 2px; font-weight: normal; font-size: theme(fontSize.xs);
	margin: auto;
}

.enable-button {
	margin-top: 10px; margin-left: 15px; margin-right: 15px; text-align: center; padding: 3px; padding-top: 6px; padding-bottom: 6px; background-color: theme(colors.yellow.300); color: theme(colors.yellow.950); font-size: theme(fontSize.xs);
}

.steps-progressbar-container {
	padding-bottom: 0px; padding-left: 15px; padding-right: 15px; padding-top: 0px;
}

.ui-layout-west .content {
	padding-right: 0px;
}

.sidebar-menu-item-green {
	border-bottom: 2px solid green !important;
}

/* VidCruiter Forms */

.vidcruiter-form-header {
	padding: 25px; padding-top: 15px; padding-bottom: 25px;
}

.vidcruiter-form-header h2 {
	font-size: 22px;
}

.vidcruiter-form-header p.description {
	color: gray; margin-top: 0px; margin-bottom: 0px;
}

.vidcruiter-form-content {
	padding-left: 25px; padding-right: 25px; padding-bottom: 20px;
}

.vidcruiter-form-footer {
	padding: 15px; padding-bottom: 15px; padding-top: 15px; text-align: left;
}

.vidcruiter-form-content p.description, .strong-text {
  @apply mt-0 text-left text-neutral-700 font-bold tracking-wide;
}
.vidcruiter-form-content .no-margin-field-container .field-container {
	margin-left: 0px;
}
.vidcruiter-form-content .field-container {
	margin-left: 10px; margin-top: 15px;
}

.vidcruiter-form-content .check-field .left {
	float: left;
}

.vidcruiter-form-content .check-field .right {
	float: left; margin-left: 10px; font-size: theme(fontSize.base);
}

.vidcruiter-form-content .divider {
	@apply w-full my-5 h-px;
}

.vidcruiter-form-content input[type=text], .vidcruiter-form-content input[type=password] {
  @apply text-lg w-112;
}

.vidcruiter-form-content input[type=text].smaller, .vidcruiter-form-content input[type=password].smaller {
  @apply text-sm w-112;
}

.vidcruiter-form-content input[type=text].smallest, .vidcruiter-form-content input[type=password].smallest {
  width: 250px;
  @apply text-xs;
}

.vidcruiter-form-content textarea {
	height: 100px; padding: 10px; font-size: theme(fontSize.lg); line-height: 22px;
  @apply w-112;
}

.vidcruiter-form-content .question .left {
	float: left; width: 380px;
}

.vidcruiter-form-content .question .right {
	float: left; margin-left: 60px; margin-top: 25px; font-size: theme(fontSize.base);
}

.vidcruiter-form-header .divider {
	border-bottom: 1px solid theme(colors.neutral.300); height: 1px; width: 100%; margin-top: 20px;
}

.vidcruiter-form-footer .divider {
	border-bottom: 1px solid theme(colors.neutral.300); height: 1px; width: 100%; margin-bottom: 20px;
}

/* Users */

.user-box {
	padding: 10px; padding-top: 10px; padding-bottom: 10px;
	min-width: 300px;
}

.user-box .left {
	float: left;
}

.user-box .left img {
	width: 75px; height: 75px;
}

.user-box .right {
	float: left; font-size: theme(fontSize.base); margin-top: 10px; margin-left: 20px;
}

.user-box .change {
	float: left; margin-left: 60px; margin-top: 4px;
}

.selectable-user {
	border: 2px solid theme(colors.neutral.50);
  @apply w-24 p-1.5 ml-3 mt-2.5 mr-2 text-center overflow-hidden float-left;
}

.selectable-user:hover {
	border: 2px solid theme(colors.neutral.200);
}

.selectable-user-selected {
	float: left;
	overflow: hidden;
	text-align: center;
	border: 2px solid white;
	background: linear-gradient(to bottom,  rgba(255,255,255,1) 0%,rgba(251,251,251,1) 49%,rgba(252,252,252,1) 50%,rgba(255,255,255,1) 100%);
  @apply shadow-xs w-24 px-1.5 py-1 mr-2 mb-1;
}

.users-list-user-label {
	float: left; background-color: theme(colors.white); color: theme(colors.sky.600); font-size: theme(fontSize.xs); padding-left: 5px; padding-right: 5px; margin-right: 5px; margin-bottom: 5px;
}

.users-list-user-label .left {
	float: left; padding: 2px; font-weight: bold;
}

.users-list-user-label .right {
	float: left; margin-left: 5px; padding: 0px; color: white;
}

.users-list-user-label .right a {
	text-shadow: none;
	color: theme(colors.slate.200);
	opacity: 1;
	font-size: theme(fontSize.sm);
}

.users-list-user-label .right a:hover {
	color: white;
}

.selectable .selectable-design-label {
	z-index: 9999999999; position: absolute; background-image: url('transparent_black.png'); color: white; padding: 5px; padding-left: 15px; padding-right: 15px; left: 0px; top: 15px; font-size: theme(fontSize.lg);
}

.selectable:hover .selectable-design-label {
	background-color: black;
	background-image: none;
}

.green-header {
  @apply pl-2.5 py-0.5 mb-5 bg-vidgreen;

  &.-flex {
    display: flex; align-items: center; justify-content: center;

    h2 {
      flex-grow: 1; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; min-width: 0;
    }

    a.green-header-link {
      white-space: nowrap;
    }
  }
}

.side-colored-indicator {
	position: absolute; background-color: theme(colors.vidgreen); color: theme(colors.white); padding: 3px; width: 17px; text-align: center; font-size: theme(fontSize.base); font-weight: bold; left: -49px;
}

.green-header-link, .vidcruiter-language-selector .green-header-link button {
  outline: 0;
	display: inline-block;
    box-sizing: border-box;
    float: right;
    color: white;
    height: 100%;
	padding: 9px;
    padding-bottom: 0px;
    padding-top: 0px;
	padding-left: 15px;
    padding-right: 15px;
	text-decoration: none;
	font-weight: bold;
  @apply text-sm leading-10;
}

.vidcruiter-custom-design .green-header-link, .vidcruiter-custom-design  .vidcruiter-language-selector button.green-header-link {
  color: inherit;
}

.green-header-link-icon {
  margin-right: 0px; font-size: theme(fontSize.lg); padding-top: 0px; padding-bottom: 0px; line-height: 40px;
}

.green-header-link-icon > .fa-times {
  position: relative;
  top: 2px;  
}

.vidcruiter-custom-design .green-header-link:hover, .vidcruiter-custom-design .vidcruiter-language-selector button.green-header-link:hover {
  color: inherit;
  background-color: rgba(0,0,0, 0.05);
}

.green-header-link:hover {
	color: white;
	text-decoration: none;
    background-color: theme(colors.green.700);
	top: 0px;
}

.green-header h2, .green-header h3 {
  @apply py-2 text-sm bold text-white;
}

/* This is all stuff that relies on box-shadow that got messed up because of CSS ordering.. need to fix these so they still look good */
.question-list-item-container {
  @apply pt-0 pb-5 w-[600px];
}

.question-list-item-watermark {
  position: absolute; right: 0px; top: 40px; color: theme(colors.neutral.100); font-weight: bold; font-size: 40px;
}

.question-list-item {
  padding: 10px;
  width: 600px;
  min-height: 78px;
  margin: auto;
  margin-left: 40px;
  background: theme(colors.white);
  @apply rounded;
}
/* End */

.box-shadow {
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.4);
  background-color: white;
  padding: 0px;
  margin-bottom: 20px;
  /*  border-top: 5px solid theme(colors.neutral.400);
*/
}
.box-shadow-hoverable:hover,
.shadow-blue {
  box-shadow: 1px 1px 4px theme(colors.sky.700);
}
.shadow-v3 {
  @apply shadow-tight;
}

/* Pages with a custom design need extra space at the bottom (career pages) */
.vidcruiter-custom-design {
  margin-bottom: 50px;
}

.vidcruiter-custom-design .green-header {
  background: theme(colors.neutral.200);
}

.vidcruiter-custom-design .green-header h2, .vidcruiter-custom-design .green-header h3 {
	color: theme(colors.neutral.700);
	font-weight: bold;
    text-shadow: 0px 0px 1px theme(colors.white);
}

.vidcruiter-custom-design .ui-draggable .ui-dialog-titlebar, .live-interview-layout .ui-draggable .ui-dialog-titlebar {
  background-image: url("gray_header_bg.png");
  border-bottom: 1px solid theme(colors.neutral.300);
}

.vidcruiter-custom-design .ui-dialog .ui-dialog-title, .live-interview-layout .ui-dialog .ui-dialog-title {
  color: theme(colors.neutral.700);
  font-weight: bold;
  text-shadow: 0px 0px 1px theme(colors.white);
}

.ui-dialog .ui-dialog-titlebar {
  padding-top: 7px; padding-bottom: 7px;
}

.vidcruiter-custom-design .ui-dialog .ui-dialog-titlebar-close, .live-interview-layout .ui-dialog .ui-dialog-titlebar-close {
  color: theme(colors.neutral.400);
  text-decoration: none;
  font-size: theme(fontSize.xl);
  right: 0px;
}

.vidcruiter-custom-design .ui-dialog .ui-dialog-titlebar-close:hover, .live-interview-layout .ui-dialog .ui-dialog-titlebar-close:hover {
 color: theme(colors.neutral.700);
}

.margin-top {
	margin-top: 10px;
}

.no-margin-bottom {
	margin-bottom: 0px;
}


.question-list-connector {
	height: 100%;
	width: 20px;
	padding-left: 6px;
	border-top: 3px solid theme(colors.neutral.100);
	border-bottom: 3px solid theme(colors.neutral.100);
	border-left: 3px solid theme(colors.neutral.100);
}

.question-list-connector-right {
	height: 100%;
	width: 20px;
	padding-left: 6px;
	border-top: 3px solid theme(colors.neutral.100);
	border-bottom: 3px solid theme(colors.neutral.100);
	border-right: 3px solid theme(colors.neutral.100);
}

.question-list-content {
  @apply pt-5 text-left pl-9;
}

.question-list-item-line-section {
	border-top: 3px solid theme(colors.neutral.100); width: 50px; margin-top: 53px; float: left;
}

.question-list-item-line {
	border-top: 3px solid theme(colors.neutral.100); width: 50px; top: 53px; position: absolute; z-index: 10;
}

.question-list-item-header {
	border: 4px solid theme(colors.white); padding: 10px; width: 100px; height: 100px; margin-bottom: 0px; text-align: center; margin: auto;
}

.question-list-item-header p {
	font-weight: bold; color: theme(colors.neutral.400);
}

.question-list-item-header p.application-form {
	padding-top: 31px;
}

.question-list-item-header p.phone-interview {
	padding-top: 31px;
}

.question-list-item-header p.questions {
	padding-top: 26px;
}

.question-list-item-header p.references {
	padding-top: 40px;
}

.question-list-item-header p.conclusion {
	padding-top: 40px;
}

.question-list-item-header p.review {
	padding-top: 40px;
}

.question-list-item-header p.report {
	padding-top: 40px;
}

.question-list-item-header p span.number {
	font-size: 28px; color: theme(colors.neutral.600);
}

.question-list-item {
  @apply bg-neutral-50 border border-neutral-200;
}

.question-list-item-header-container {
  @apply mt-0 ml-5;
}

.question-list-item-screenshot-container {
	float: left; width: 90px;
}

.question-list-item-screenshot {
	height: 65px;
	position: absolute;
	top: 3px;
	left: 10px;
  z-index: 4;
/*    border: 1px solid theme(colors.neutral.100);
    border-right: 1px solid theme(colors.neutral.200);
    border-top: 1px solid theme(colors.neutral.200);
    border-left: 1px solid theme(colors.neutral.200);
    border-bottom: 1px solid theme(colors.neutral.200);
*/}


.question-time {
	position: absolute;
	top: 40px;
	left: -1px;
	background-image: url("transparent_black.png");
	color: white;
	font-weight: normal;
	padding: 0px;
	padding-right: 5px;
	padding-left: 5px;
	font-size: theme(fontSize.2xs);
	z-index: 5;
}

.question-list-item .question-list-item-hoverable {
	display: none;
}

.question-list-item-content {
	margin-left: 120px;
  @apply mr-2;
}

.question-list-item-content-question {
	height: 70px; width: 375px; font-weight: 200; color: theme(colors.neutral.500);
  @apply text-base;
}

.question-list-item-content-stat {
	width: 60px; padding-right: 10px; padding-left: 10px;
}

.question-list-item-content-stat .number {
	font-size: 22px; font-weight: bold; margin-top: 12px;
}

.question-list-item-content-stat .label {
	color: theme(colors.neutral.500);
}

.question-list-button {
	height: 40px; width: 40px;
}

.question-list-button .button-label {
	font-size: theme(fontSize.xs);
}

.question-list-actions {
	margin-top: 10px; margin-left: 40px;
}

.question-list-action-item {
	float: right; margin-left: 15px; margin-top: 5px;
}

.question-list-action-item-last {
	border-left: 1px solid theme(colors.neutral.300); padding-left: 15px;
}

.question-list-actions-label {
	float: left; margin-left: 0px; padding-top: 10px; font-weight: bold; color: theme(colors.neutral.500);
}

.question-list-item-content-stat-two-button {
	padding-left: 70px;
}

.question-list-item-content-stat-reorderable-true {
	padding-left: 20px;
}

.field-icon-single-text, .field-icon-typing_test, .field-icon-copy, .field-icon-embedded-test {
	float: left; padding-top: 3px; padding-left: 10px; padding-right: 10px;
}

.field-icon-ranking {
	float: left; padding-top: 3px; padding-left: 10px; padding-right: 10px;
}
.field-icon-scoring {
	float: left; padding-top: 3px; padding-left: 10px; padding-right: 10px;
}

.field-icon-paragraph-text {
	float: left; padding-top: 3px; padding-left: 10px; padding-right: 10px;
}

.field-icon-echosign {
	float: left; padding-top: 3px; padding-left: 10px; padding-right: 10px;
}

.field-icon-select, .field-icon-rating-stars {
	float: left; padding-top: 1px; padding-left: 13px; padding-right: 13px;
}

.field-icon-checkbox {
	float: left; padding-top: 1px; padding-left: 14px; padding-right: 14px;
}

.field-icon-section-break,
.field-icon-script-reading {
	float: left; padding-top: 1px; padding-left: 8px; padding-right: 8px;
}

.field-icon-date {
	float: left; padding-top: 1px; padding-left: 13px; padding-right: 13px;
}

.field-icon-file {
	float: left; padding-top: 1px; padding-left: 13px; padding-right: 13px;
}

.field-icon-video-file-upload {
	float: left; padding-top: 1px; padding-left: 13px; padding-right: 13px;
}

.field-icon-image {
	float: left; padding-top: 1px; padding-left: 13px; padding-right: 13px;
}

.field-icon-download {
	float: left; padding-top: 1px; padding-left: 13px; padding-right: 13px;
}

.field-icon-video {
	float: left; padding-top: 1px; padding-left: 13px; padding-right: 12px;
}

/* Editable form */

.echosign-widget, .signature-widget {
	width: 100%;
	height: 600px;
}

.vidcruiter-editable-form .vidcruiter-field {
  @apply p-2.5 !important;
  @apply border-3 border-solid border-transparent cursor-pointer py-1.5;
}

.vidcruiter-editable-form .vidcruiter-field-section-break {
	@apply pt-4;
}

.vidcruiter-editable-form .vidcruiter-field:hover {
	@apply border-sky-50 bg-sky-50;
}

.vidcruiter-editable-form .vidcruiter-field-selected {
	@apply border-neutral-100 bg-white !important;
}

#editable-form-sidebar-container {
	position: relative;
	width: 320px;
	padding-top: 0px;
	padding-bottom: 0px;
	padding-left: 0px;
	border-top: 1px solid white;
	z-index: 11;
}

#editable-form-sidebar {
	position: absolute;
	top: 0px;
}

.questionnaire-list-item {
	@apply h-14 mr-5 float-left border border-solid border-white;
}

.questionnaire-list-item .name {
	color: theme(colors.neutral.600);
	font-size: theme(fontSize.base);
	@apply flex-1 max-w-md font-normal;
}

#questionnaire-list {
}

.questionnaire-list-item-selected {
/*	  background-color: theme(colors.sky.100);
  color: theme(colors.sky.600) !important;
*/
  border: 2px solid theme(colors.blue.200);
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
}

.questionnaire-list-item-selected .name {
	color: theme(colors.neutral.700);
}

.questionnaire-list-item .buttons {
	display: none;
}

.questionnaire-list-item-selected .buttons {
	display: inline;
}

/* QUESTION PAGE FORM */

.add-question-page-form {
	margin-top: 6px;
}

.add-question-page-form input[type=text] {
	width: 380px; height: 20px; font-size: theme(fontSize.sm);
	padding-top: 3px; padding-bottom: 3px;
}

/* NAME EDIT FORM */

.edit-name-link {
	@apply px-1 py-2 w-full cursor-text;

	.edit-name-icon {
		color: theme(colors.sky.700);
		margin-left: 2px;
		font-size: theme(fontSize.sm);
		display: none;
	}
}

.edit-name-link:hover {
	color: theme(colors.sky.700);
	background-color: theme(colors.sky.100);
}


.edit-name-link.-with-icon:hover {
	color: initial;
	background-color: initial;

	.edit-name-icon {
		display: initial;
	}
}

.edit-name-question-page-form-builder:hover {
	cursor: pointer;
	background-color: inherit;
	color: theme(colors.neutral.700);
}

/* QUESTION EDIT NAME FORM */

.edit-question-name-form input[type=text] {
  @apply w-48 text-sm py-2;
}

.edit-question-name-form {
	margin-top: 8px;
}

.edit-question-name-form .name {
	float: left;
  @apply w-48;
}

.edit-question-name-form .save {
	float: left;
  @apply ml-10 mt-1.5;
}

.edit-question-name-form .cancel {
	float: left;
  @apply ml-2.5 mt-1.5;
}

/* QUESTIONNAIRE FORM */

#new-questionnaire-form input[type=text] {
	width: 200px;
}

.edit-questionnaire-name-form input[type=text] {
	@apply w-full;
}

.edit-questionnaire-name-form .name {
	@apply flex-1;
}

#sms-templates-list .name, #email-templates-list .name, #career-categories-table .name, #privacy-policy-list .name {
	float: left; width: 240px;
}

#sms-templates-list .save, #email-templates-list .save, #career-categories-table .save, #career-category-group-table .save, #privacy-policy-list .save {
	float: left; margin-left: 40px;
}

#sms-templates-list .cancel, #email-templates-list .cancel, #career-categories-table .cancel, #career-category-group-table .cancel, #privacy-policy-list .cancel {
	float: left; margin-left: 10px;
}

.edit-questionnaire-name-form .save {
  @apply ml-0 mt-0;
}

.edit-questionnaire-name-form .cancel {
  @apply ml-0 mt-0;
}

#career-category-group-table .name {
	float: left; width: 150px;
}

#career-category-group-table input[type=text] {
	width: 150px;
}

/* BUCKET FORM */

/* QUESTIONNAIRE FORM */

form#new_bucket input[type=text] {
	width: 200px; height: 20px; font-size: theme(fontSize.sm);
	padding-top: 3px; padding-bottom: 3px;
}

#questionnaire-form-tab-interview-content { padding-top: 30px; padding-bottom: 0px; padding-left: 0px; text-align: center;
}

.dialog-header {
	color: theme(colors.green.700);
	font-family: theme(fontFamily.sans);
	font-size: theme(fontSize.lg);
	font-weight: 300;
}

/* Selectable Items */

.selectable-item {
}

.selectable-item:hover {
}

.video-item-selectable {
	background: inherit;
}

.video-item-selectable:hover {
	background: inherit;
}

.video-item-selectable:hover .description {
  color: theme(colors.sky.600);
  text-decoration: underline;
}

.thumbnail {
  width: 45px; height: 35px;
}

.thumbnail.large {
  width: 86px; height: 63px;
}

.thumbnail .thumbnail-entry {
  width: 45px; height: 35px; border-radius: 5px; text-align: center; background-color: theme(colors.neutral.200);
}

.thumbnail.large .thumbnail-entry {
  width: 86px; height: 63px;
}

.thumbnail .thumbnail-entry .thumbnail-icon {
  color: theme(colors.neutral.500); margin-bottom: 0px; padding-top: 5px; line-height: 0px; font-size: theme(fontSize.base);
}

.thumbnail.large .thumbnail-entry .thumbnail-icon {
  font-size: 22px; padding-top: 8px;
}

.thumbnail .thumbnail-entry .thumbnail-text {
  color: theme(colors.neutral.500); font-size: 6px; padding-top: 5px; line-height: 0px; margin-bottom: 0px; text-transform: uppercase;
}

.thumbnail.large .thumbnail-entry .thumbnail-text {
  font-size: theme(fontSize.xs); padding-top: 8px;
}


.video-item-selectable img {
	width: 45px;
}

.video-item-selectable .description {
	padding: 5px;
	font-size: theme(fontSize.xs);
}

/* Vidcruiter Tabs */


/* Video Picker */

#video-picker-video-tab #video-container {
	flex-grow: 1; margin-left: 10px;
}

#video-tags {
	@apply ml-5 w-56;
}

#video-tags-list {
	@apply w-48;
}

#video-picker-caption-tab {
	text-align: left; padding: 10px; padding-top: 0px;
}

#video-picker-caption-tab p.caption {
	margin-top: 20px;
}

#video-picker-caption-tab p.caption textarea {
	width: 570px; height: 230px;
}

#video-picker-footer form {
	margin-bottom: 0px;
}

#video-picker-footer .use-video {
	outline: 0;
}

#video-picker-footer .cancel {
	outline: 0; margin-left: 5px;
}

#video-picker-footer .remove {
	margin-left: 15px; margin-top: 5px;
}

.edit-name-link-video {
	width: 95%;
}

#video-list-search-close {
	position: relative;
}

#video-list-search-close div {
	position: absolute; z-index: 1002; right: 25px; top: 7px;
}

/* NO SELECTED VIDEO */

.selected-video-none {
	@apply m-8 h-56 w-[calc(100%-4rem)];
}

.selected-video-none .selected-video-none-content {
	width: 500px; text-align: center; padding-top: 20px; padding-bottom: 20px;
}

.selected-video-none .selected-video-none-content p.description {
	font-size: theme(fontSize.2xl);
	color: theme(colors.neutral.300);
	text-align: center;
	font-weight: normal;
}

.selected-video-none .selected-video-none-content p.options {
	color: theme(colors.neutral.600); font-size: theme(fontSize.sm); margin-top: 15px;
}

/* VIDEO RECORDER */

.video-recorder-name {
	@apply w-full;
}

/* FORM BUILDER */

#form-builder-header-right {
	float: right; margin-right: 20px; margin-top: 20px;
}

#form-builder-header-left {
	float: left; margin-top: 20px; margin-left: 10px;
}

#form-builder {
  @apply flex flex-row mt-2;
}

.add-button-large-content {
	float: left; width: 70px; text-align: center;
}

.add-button-large-content a {
	font-size: 50px; width: 60px; height: 45px; padding-top: 14px; padding-bottom: 0px; padding-left: 0px; padding-right: 0px;
}

.add-button-large-content p {
	margin-top: 5px; font-size: theme(fontSize.xs); color: theme(colors.neutral.400);
}

#form-builder .connecting-line {
  @apply w-8 mr-6;
}

#form-builder .connecting-line-container {
	position: relative;
}

#form-builder .connecting-line-container div {
	margin-top: 25px; width: 30px;
}

#form-builder-right {
	@apply w-full mx-4;
}

#form-builder-right table {
	height: 100%;
}

.table-divider {
	height: 100%;
}

.table-divider-inner {
	height: 100%; border-left: 1px dotted theme(colors.neutral.300); width: 1px; margin-right: 5px;
}

.table-divider img {
	height: 100%;
}

#form-builder-current-form {
  @apply flex flex-col items-start justify-start pl-12;
}

.question-page-video-buttons {
	margin-bottom: 20px; padding: 10px;
  @apply w-112;
}

.form-builder-video {
	width: 440px; max-height: 350px; padding: 5px; border-left: 2px solid white; border-bottom: 1px solid white;
}

#form-builder-navigation {
	width: 540px;  padding-left: 50px;
}

#error_explanation {
	padding-left: 20px;
	padding-bottom: 20px;
	@apply text-sm;
}

#error_explanation h2 {
	color: red;
	font-size: theme(fontSize.base);
	@apply font-normal;
}

.questionnaire-link-item {
	padding: 0px; color: theme(colors.neutral.500);
}
.questionnaire-link-item .title {font-weight: bold;}
.questionnaire-link-item .link {
	@apply font-light;
	padding-right: 20px;
}
.questionnaire-link-item .clipboard {margin-top: 10px; font-size: theme(fontSize.2xs); font-weight: normal; font-style: italic;}

.vidcruiter-dropdown-item a {
	color: theme(colors.neutral.500);
	display: block;
	padding-left: 10px;
	padding-right: 10px;
	padding-top: 3px;
	padding-bottom: 3px;
	min-width: 100px;
}

.vidcruiter-dropdown-item a:hover {
	background: theme(colors.blue.500);
	color: white;
	text-decoration: none;
}

.business-process-list-item .process-name {
	color: theme(colors.neutral.700); margin-bottom: 2px; font-size: theme(fontSize.lg);
}

.business-process-list-item .process-applicants {
	color: theme(colors.neutral.400); font-size: theme(fontSize.sm);
}

.business-process-list-item .process-info {
	padding: 2px; padding-left: 10px;
}

.business-process-list-item .process-actions {
	padding-right: 5px; text-align: left;
}

.business-process-list-item .process-avatar {
	float: left; margin-right: 10px; margin-top: 3px; width: 35px; height: 35px;
}

.business-process-list-item .process-avatar img {
	width: 35px; height: 35px;
}

.business-process-list-item .process-owner {
	float: left; width: 120px; overflow: hidden;
}

.business-process-list-item .process-owner .username {
	font-weight: bold; margin-bottom: 0px;
}

.business-process-list-item .process-owner .members {
	margin-top: 0px; color: theme(colors.neutral.400);
	margin-bottom: 0px;
}

.business-process-list-item .process-buttons {
	margin-right: 20px;
}

.datetime-select-container select {
	width: 80px;
}


.ui-timepicker-div .ui-widget-header { margin-bottom: 8px; }
.ui-timepicker-div dl { text-align: left; }
.ui-timepicker-div dl dt { height: 20px; margin-bottom: -25px; margin-left: 10px; }
.ui-timepicker-div dl dd { margin-left: 85px; margin-top: 0px; margin-bottom: 0px; }
.ui-timepicker-div td { font-size: 90%; }
.ui-tpicker-grid-label { background: none; border: none; margin: 0; padding: 0; }
#ui-datepicker-div { font-size: theme(fontSize.xs); }
.ui-timepicker-div .ui_tpicker_unit_hide{ display: none; }

.ui_tpicker_timezone select {
	width: 300px;
}

.ui_tpicker_hour_slider select {
	@apply w-20 mb-2;
}

.ui_tpicker_minute_slider select {
	@apply w-20;
}

.ui_tpicker_time_label, .ui_tpicker_time {
	display: none;
}

.ui-datepicker-header {
	border-radius: 0px !important;
	-moz-border-radius-topleft: 0px  !important;
	border-top-left-radius: 0px !important;
}

.dashed-separator {
	background-image: url('bg_texture.png'); border-bottom: 1px dashed theme(colors.neutral.300); border-top: 1px dashed theme(colors.neutral.300); color: theme(colors.neutral.700); padding: 15px; margin-bottom: 30px; text-align: left; font-size: theme(fontSize.base); font-weight: bold;
}

.dashed-box {
	background-image: url('bg_texture.png'); border: 1px dashed theme(colors.neutral.300); color: theme(colors.neutral.700);
}

.rating-strikethrough, .weight-strikethrough, .questionnaire-strikethrough, .question-page-strikethrough, .live-interviews-strikethrough, .live-interview-archive-notes-strikethrough, .strikethrough {
	text-decoration: line-through;
}

.translation_missing {
	color: red;
}

.translation-subdivider {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @apply border-box w-full block bg-neutral-100 px-2.5 py-1.5 text-base;
}

.translation-table td {
	word-break: break-word;
	@apply py-2.5 px-4;
}

.translation-table tbody:nth-of-type(even) tr {
  @apply bg-neutral-100;
}

.translation-table td input {
	margin-bottom: 0px;
}

.li-apply-text {
	display: none !important;
}

.advanced-search-link {
	float: right; margin-left: 10px; margin-right: 5px; margin-top: 12px;
}

.advanced-search-link a {
	font-size: theme(fontSize.lg); color: theme(colors.neutral.700);
}

.advanced-search-link a:hover {
	text-decoration: none;
}

#new-division-form {
	padding: 15px; padding-bottom: 0px; width: 575px; margin-left: 10px; margin-top: 10px;
}

#new-division-form .header {
	border-bottom: 1px dotted theme(colors.neutral.300); padding: 10px; padding-top: 0px; font-weight: bold; color: theme(colors.neutral.500);
}

#new-division-form .header a {
	float: right; font-weight: normal;
}

#new-division-form .name-this {
	margin-top: 20px;
}

#new-division-form .users {
	border: 1px solid theme(colors.neutral.300); background-color: theme(colors.neutral.100); padding: 10px;
}

#new-division-form .users .links {
	margin-bottom: 10px;
}

#new-division-form .users .links .deselect {
	margin-left: 10px;
}

#new-division-form .users .user {
	float: left; border-bottom: 1px dotted theme(colors.neutral.300); padding: 3px; margin-right: 20px; margin-bottom: 12px; width: 250px;
}

#new-division-form .users .user .select {
	padding-right: 5px; text-align: left;
}

#new-division-form .users .user .avatar {
	padding-left: 5px; text-align: left; width: 35px;
}

#new-division-form .users .user .name {
	padding-left: 10px; padding-right: 10px; text-align: left; color: theme(colors.neutral.800); font-weight: bold;
}

#company-divisions-header {
	border-bottom: 1px dotted theme(colors.neutral.300); padding: 10px;
}

#company-divisions-header .divisions {
	float: left; font-weight: bold; color: theme(colors.neutral.500); margin-top: 6px;
}

.division-item {
	padding: 10px; margin-bottom: 0px; border-bottom: 1px dotted theme(colors.neutral.300); padding-bottom: 15px;
}

.division-item .destroy {
	float: right; margin-left: 20px; margin-top: 5px; margin-right: 10px;
}

.division-item .division-content {
	float: left; padding-left: 20px; text-align: left; margin-top: 10px;
}

.division-item .division-name {
	color: theme(colors.neutral.800); font-weight: 200; font-size: theme(fontSize.base); margin-bottom: 5px;
}

.division-item .division-name-form {
	padding: 10px; padding-left: 20px; border: 1px solid theme(colors.neutral.300); background-color: theme(colors.neutral.100);
}

.division-item .division-user-list {
	text-align: left; margin-top: 20px;
}

.division-item .division-user {
	float: left; border-bottom: 1px dotted theme(colors.neutral.300); padding: 3px; margin-right: 20px; margin-bottom: 12px; width: 260px;
}

.division-item .division-user .avatar {
	padding-left: 5px; text-align: left; width: 35px;
}

.division-item .division-user .name {
	padding-left: 10px; padding-right: 10px; text-align: left; color: theme(colors.neutral.800); font-weight: bold;
}

.division-item .division-user .remove {
	padding-right: 5px; text-align: right;
}

.division-item .division-user .remove a {
	font-weight: bold; font-size: theme(fontSize.lg); color: theme(colors.neutral.400);
}

.dotted-header {
	border-bottom: 1px dotted theme(colors.neutral.300); padding: 10px; font-weight: bold; color: theme(colors.neutral.500);
}

.company-invitation {
	padding: 10px; margin-bottom: 0px; border-bottom: 1px dotted theme(colors.neutral.300); padding-bottom: 15px;
}

.company-invittion-right {
	float: right; margin-left: 20px; margin-top: 5px; margin-right: 10px;
}

.company-invitation-left {
	float: left; padding-left: 20px; text-align: left; margin-top: 10px;
}

.company-invitation-left p {
	color: theme(colors.neutral.800); font-weight: 200; font-size: theme(fontSize.base); margin-bottom: 5px;
}

#company-users {
	padding-top: 30px;
}

#company-users .company-user {
	float: left; width: 154px; text-align: center; height: 165px; cursor: pointer;
}

#company-users .company-user .name-container {
	position: relative; text-align: center;
}

#company-users .company-user .name {
	color: theme(colors.neutral.800); font-weight: bold; margin-top: 10px; font-size: theme(fontSize.xs);
}

#company-users .company-user:hover .name {
	color: theme(colors.blue.500);
	text-decoration: underline;
}

#company-users .company-user .user-type {
	margin-bottom: 5px; margin-top: 10px; font-weight: bold; font-size: theme(fontSize.xs); color: theme(colors.neutral.400);
}

#settings-user-tab {

}

#settings-user-tab .sidebar {
	float: left; width: 250px;
}

.dotted-divider {
	width: 100%; border-top: 1px dotted theme(colors.neutral.300); margin-top: 30px;
}

#user-settings-menu {
	margin-top: 30px; width: 200px;
}

#user-settings-content {
	float: left; margin-left: 20px; text-align: left;  width: 620px;
}

.person-item {
	border-top: 1px solid white;
	border-left: 1px solid white;
	border-bottom: 1px solid theme(colors.neutral.100);
	border-right: 1px solid theme(colors.neutral.100);
}

#time-remaining {
	position: fixed; top: 0px; left: 0px; padding: 20px; z-index: 9999991;
}

#time-remaining p {
	font-size: theme(fontSize.xs); margin-bottom: 4px; text-align: center;
}

#time-remaining .hasCountdown {
  background-image: none;
  @apply pt-1 rounded-md border-0;
}

.video-review-countdown.hasCountdown {
  @apply bg-none border-none shadow-none bg-inherit p-0 pt-0;
}

.video-review-countdown.hasCountdown .countdown_row {
  @apply text-sm;
}

#time-remaining:hover .hasCountdown {
  @apply bg-sky-100;
}

#time-remaining .countdown_row {
  @apply font-bold text-gray-950 text-2xl;
}

.setup-menu-text {
	position: relative; margin: auto;
}

.setup-menu-text div {
	position: absolute; width: 159px; text-align: center;
}

.white-border {
	border: 1px solid white;
}


.hide_labels{
	text-indent:-9999px;
	height: 0px;
	width: 0px;
	overflow: hidden;
    position: absolute;
}

.aria-offscreen {
  position: absolute; /* for Internet Explorer */
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0;
  border: 0;
  height: 1px;
  width: 1px;
  overflow: hidden;
}

.aria-offscreen:focus {position:relative;
  clip:auto;
  width:auto;
  height:auto;
  overflow:auto;
}

.misspelled_word {
	color: red;
	text-decoration: underline;
}

.misspelled_count {
	border-top: 1px solid darkgrey;
	font-size: theme(fontSize.xs);
	color: red;
	width: 100%;
	text-align: right;
}

form.button_to button {
	margin-right: 10px;
}

#live-interviews-list .videoplayer-and-notes-container { margin-bottom: 60px; }

.vidcruiter-public-profile-question-page-answer .answer .videoplayer-container {margin-bottom: 60px;}

.data-archive form { margin: 0px;  }

.data-archive td { text-align: center; }
.select2-300.select2-container .select2-results {max-height: 300px;}
.select2-300 .select2-results {max-height: 300px;}
.select2-300 .select2-choices {min-height: 250px; max-height: 250px; overflow-y: auto;}













/*!
 * Bootstrap v2.2.2
 *
 * Copyright 2012 Twitter, Inc
 * Licensed under the Apache License v2.0
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Designed and built with all the love in the world @twitter by @mdo and @fat.
 */
.input-block-level {
  display: block;
  width: 100%;
  min-height: 30px;
  box-sizing: border-box;
}

.container,
.navbar-static-top .container,
.navbar-fixed-top .container,
.navbar-fixed-bottom .container {
  width: 940px;
}

.container {
  margin-right: auto;
  margin-left: auto;
}
.container:before,
.container:after {
  display: table;
  content: "";
  line-height: 0;
}
.container:after {
  clear: both;
}
.container-fluid {
  padding-right: 20px;
  padding-left: 20px;
}
.container-fluid:before,
.container-fluid:after {
  display: table;
  content: "";
  line-height: 0;
}
.container-fluid:after {
  clear: both;
}

.uneditable-input,
.uneditable-textarea {
  color: theme(colors.neutral.400);
  background-color: theme(colors.neutral.50);
  border-color: theme(colors.neutral.300);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.025);
  cursor: not-allowed;
}
.uneditable-input {
  overflow: hidden;
  white-space: nowrap;
}
.uneditable-textarea {
  width: auto;
  height: auto;
}

.radio,
.checkbox {
  min-height: 20px;
  padding-left: 20px;
}
.radio input[type="radio"],
.checkbox input[type="checkbox"] {
  float: left;
  margin-left: -20px;
}
.controls > .radio:first-child,
.controls > .checkbox:first-child {
  padding-top: 5px;
}
.radio.inline,
.checkbox.inline {
  display: inline-block;
  padding-top: 5px;
  margin-bottom: 0;
  vertical-align: middle;
}
.radio.inline + .radio.inline,
.checkbox.inline + .checkbox.inline {
  margin-left: 10px;
}
.input-tiny {
  width: 40px;
}
.input-mini {
  width: 60px;
}
.input-small {
  width: 90px;
}
.input-medium {
  width: 150px;
}
.input-large {
  width: 210px;
}
.input-xlarge {
  width: 270px;
}
.input-xxlarge {
  width: 530px;
}

.help-inline {
  display: inline-block;
  /* IE7 inline-block hack */
  vertical-align: middle;
  padding-left: 5px;
}
.input-append,
.input-prepend {
  margin-bottom: 5px;
  font-size: 0;
  white-space: nowrap;
}
.input-append input,
.input-prepend input,
.input-append select,
.input-prepend select,
.input-append .uneditable-input,
.input-prepend .uneditable-input,
.input-append .dropdown-menu,
.input-prepend .dropdown-menu {
  font-size: theme(fontSize.sm);
}
.input-append input,
.input-prepend input,
.input-append select,
.input-prepend select,
.input-append .uneditable-input,
.input-prepend .uneditable-input {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
  border-radius: 0 4px 4px 0;
}
.input-append input:focus,
.input-prepend input:focus,
.input-append select:focus,
.input-prepend select:focus,
.input-append .uneditable-input:focus,
.input-prepend .uneditable-input:focus {
  z-index: 2;
}
.input-append .add-on,
.input-prepend .add-on {
  display: inline-block;
  width: auto;
  height: 20px;
  min-width: 16px;
  padding: 4px 5px;
  font-size: theme(fontSize.sm);
  font-weight: normal;
  line-height: 20px;
  text-align: center;
  text-shadow: 0 1px 0 theme(colors.white);
  background-color: theme(colors.neutral.200);
  border: 1px solid theme(colors.neutral.300);
}
.input-append .add-on,
.input-prepend .add-on,
.input-append .btn,
.input-prepend .btn,
.input-append .btn-group > .dropdown-toggle,
.input-prepend .btn-group > .dropdown-toggle {
  vertical-align: top;
  border-radius: 0;
}
.input-append .active,
.input-prepend .active {
  background-color: theme(colors.green.300);
  border-color: theme(colors.green.600);
}
.input-prepend .add-on,
.input-prepend .btn {
  margin-right: -1px;
}
.input-prepend .add-on:first-child,
.input-prepend .btn:first-child {
  border-radius: 4px 0 0 4px;
}
.input-append input,
.input-append select,
.input-append .uneditable-input {
  border-radius: 4px 0 0 4px;
}
.input-append input + .btn-group .btn:last-child,
.input-append select + .btn-group .btn:last-child,
.input-append .uneditable-input + .btn-group .btn:last-child {
  border-radius: 0 4px 4px 0;
}
.input-append .add-on,
.input-append .btn,
.input-append .btn-group {
  margin-left: -1px;
}
.input-append .add-on:last-child,
.input-append .btn:last-child,
.input-append .btn-group:last-child > .dropdown-toggle {
  border-radius: 0 4px 4px 0;
}
.input-prepend.input-append input,
.input-prepend.input-append select,
.input-prepend.input-append .uneditable-input {
  border-radius: 0;
}
.input-prepend.input-append input + .btn-group .btn,
.input-prepend.input-append select + .btn-group .btn,
.input-prepend.input-append .uneditable-input + .btn-group .btn {
  border-radius: 0 4px 4px 0;
}
.input-prepend.input-append .add-on:first-child,
.input-prepend.input-append .btn:first-child {
  margin-right: -1px;
  border-radius: 4px 0 0 4px;
}
.input-prepend.input-append .add-on:last-child,
.input-prepend.input-append .btn:last-child {
  margin-left: -1px;
  border-radius: 0 4px 4px 0;
}
.input-prepend.input-append .btn-group:first-child {
  margin-left: 0;
}
input.search-query {
  padding-right: 14px;
  padding-left: 14px;
  /* IE7-8 doesn't have border-radius, so don't indent the padding */

  margin-bottom: 0;
  border-radius: 15px;
}
/* Allow for input prepend/append in search forms */
.form-search .input-append .search-query,
.form-search .input-prepend .search-query {
  border-radius: 0;
}
.form-search .input-append .search-query {
  border-radius: 14px 0 0 14px;
}
.form-search .input-append .btn {
  border-radius: 0 14px 14px 0;
}
.form-search .input-prepend .search-query {
  border-radius: 0 14px 14px 0;
}
.form-search .input-prepend .btn {
  border-radius: 14px 0 0 14px;
}
.form-search input,
.form-inline input,
.form-horizontal input,
.form-search textarea,
.form-inline textarea,
.form-horizontal textarea,
.form-search select,
.form-inline select,
.form-horizontal select,
.form-search .help-inline,
.form-inline .help-inline,
.form-horizontal .help-inline,
.help-inline,
.form-search .uneditable-input,
.form-inline .uneditable-input,
.form-horizontal .uneditable-input,
.form-search .input-prepend,
.form-inline .input-prepend,
.form-horizontal .input-prepend,
.form-search .input-append,
.form-inline .input-append,
.form-horizontal .input-append {
  display: inline-block;
  /* IE7 inline-block hack */
  margin-bottom: 0;
  vertical-align: middle;
}
.form-search .hide,
.form-inline .hide,
.form-horizontal .hide {
  display: none;
}
.form-search label,
.form-inline label,
.form-search .btn-group,
.form-inline .btn-group {
  display: inline-block;
}
.form-search .input-append,
.form-inline .input-append,
.form-search .input-prepend,
.form-inline .input-prepend {
  margin-bottom: 0;
}
.form-search .radio,
.form-search .checkbox,
.form-inline .radio,
.form-inline .checkbox {
  padding-left: 0;
  margin-bottom: 0;
  vertical-align: middle;
}
.form-search .radio input[type="radio"],
.form-search .checkbox input[type="checkbox"],
.form-inline .radio input[type="radio"],
.form-inline .checkbox input[type="checkbox"] {
  float: left;
  margin-right: 3px;
  margin-left: 0;
}
.control-group {
  margin-bottom: 10px;
}
legend + .control-group {
  margin-top: 20px;
  -webkit-margin-top-collapse: separate;
}

.dropup,
.dropdown {
  position: relative;
}
.dropdown-toggle {
}
.dropdown-toggle:active,
.open .dropdown-toggle {
  outline: 0;
}
.caret {
  display: inline-block;
  width: 0;
  height: 0;
  vertical-align: top;
  border-top: 4px solid theme(colors.black);
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  content: "";
  @apply dark:border-t-neutral-100;
}
.dropdown .caret {
	@apply mt-2.5 ml-0.5;
}
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  list-style: none;
  background-color: theme(colors.white);
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  background-clip: padding-box;
}
.dropdown-menu.dropdown-menu--dark {
  @apply bg-gray-950 rounded-md text-white shadow-md;
}
.dropdown-menu.pull-right {
  right: 0;
  left: auto;
}
.dropdown-menu .divider {
  height: 1px;
  margin: 9px 1px;
  overflow: hidden;
  background-color: theme(colors.neutral.200);
  border-bottom: 1px solid theme(colors.white);
}
.dropdown-menu li > a {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: normal;
  line-height: 20px;
  color: theme(colors.neutral.700);
  white-space: nowrap;
}
.dropdown-menu.dropdown-menu--dark li > a {
  @apply text-white;
}
.dropdown-menu li.delete-link > a {
	color: theme(colors.red.700);
}
.dropdown-menu li > a:hover,
.dropdown-menu li > a:focus,
.dropdown-submenu:hover > a {
  text-decoration: none;
  color: theme(colors.white);
  background-color: theme(colors.blue.400);
  /* background-image: linear-gradient(to bottom, theme(colors.sky.600), #0077b3);
  background-repeat: repeat-x; */
}
.dropdown-menu.dropdown-menu--dark li > a:hover,
.dropdown-menu.dropdown-menu--dark li > a:focus
{
  @apply bg-white bg-opacity-10;
  background-image: none;
}
.dropdown-menu .active > a,
.dropdown-menu .active > a:hover {
  color: theme(colors.white);
  text-decoration: none;
  outline: 0;
  background-color: theme(colors.blue.400);
  /* background-image: linear-gradient(to bottom, theme(colors.sky.600), #0077b3);
  background-repeat: repeat-x; */
}
.dropdown-menu .disabled > a,
.dropdown-menu .disabled > a:hover {
  color: theme(colors.neutral.400);
}
.dropdown-menu .disabled > a:hover {
  text-decoration: none;
  background-color: transparent;
  background-image: none;
  cursor: default;
}
.dropdown-menu > li:hover > a.menu-help-text, .dropdown-menu > li > .menu-help-text:hover {
	color: theme(colors.neutral.400);
	background-color: transparent !important;
	cursor:pointer;
}
.open {
}
.open  > .dropdown-menu {
  display: block;
}
.pull-right > .dropdown-menu {
  right: 0;
  left: auto;
}
.dropup .caret,
.navbar-fixed-bottom .dropdown .caret {
  border-top: 0;
  border-bottom: 4px solid theme(colors.black);
  content: "";
}
.dropup .dropdown-menu,
.navbar-fixed-bottom .dropdown .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-bottom: 1px;
}
.dropdown-submenu {
  position: relative;
}
.dropdown-submenu > .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -6px;
  margin-left: -1px;
  border-radius: 0 6px 6px 6px;
}
.dropdown-submenu:hover > .dropdown-menu {
  display: block;
}
.dropup .dropdown-submenu > .dropdown-menu {
  top: auto;
  bottom: 0;
  margin-top: 0;
  margin-bottom: -2px;
  border-radius: 5px 5px 5px 0;
}
.dropdown-submenu > a:after {
  display: block;
  content: " ";
  float: right;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 5px 0 5px 5px;
  border-left-color: theme(colors.neutral.300);
  margin-top: 5px;
  margin-right: -10px;
}
.dropdown-submenu:hover > a:after {
  border-left-color: theme(colors.white);
}
.dropdown-submenu.pull-left {
  float: none;
}
.dropdown-submenu.pull-left > .dropdown-menu {
  left: -100%;
  margin-left: 10px;
  border-radius: 6px 0 6px 6px;
}
.dropdown .dropdown-menu .nav-header {
  padding-left: 20px;
  padding-right: 20px;
}
.typeahead {
  z-index: 1051;
  margin-top: 2px;
  border-radius: 4px;
}


.collapse {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
.collapse.in {
  height: auto;
}
.close {
  float: right;
  font-size: theme(fontSize.xl);
  font-weight: bold;
  line-height: 20px;
  color: theme(colors.black);
  text-shadow: 0 1px 0 theme(colors.white);
  opacity: 0.2;
}
.close:hover {
  color: theme(colors.black);
  text-decoration: none;
  cursor: pointer;
  opacity: 0.4;
}
button.close {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
  appearance: none;
}



.dropdown-menu a {
  line-height: 18px;
}
select,
input[type="file"] {
  /* height: 28px; */
  line-height: 28px;
}



.no-resize {
  resize: none;
}

.gray-header {
  margin-left: -10px;
  margin-right: -10px;
  color: theme(colors.neutral.400);
  display: block;
  font-size: theme(fontSize.xs);
  font-weight: bold;
  line-height: 18px;
  padding: 3px 15px;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
  text-transform: uppercase;
}


/* Make the dialog appear over 1000 z-indexes (ex: Tab navigation arrows) */
.ui-front { z-index: 1002 !important; } .ui-dialog { z-index: 1003 !important; }

.ui-dialog {
  background-color: white;
  border-radius: 5px 5px 5px 5px;
  @apply shadow-lg;
}
.ui-dialog .ui-dialog-title {
  font-weight: bold;
  text-shadow: none;
}
.ui-dialog-titlebar {
  background-color: theme(colors.vidgreen);
  border-radius: 5px 5px 0px 0px;
  padding: 10px;
  padding-top: 12px;
  padding-bottom: 12px;
}
.ui-dialog-titlebar-close {
  float: right;
  color: white;
}
.ui-dialog-title {
  font-size: theme(fontSize.sm);
  font-weight: 500;
  color: white;
  text-shadow: 0px 0px 1px theme(colors.neutral.700);
}
.box-menu a {
  font-size: theme(fontSize.sm);
}
.box-menu a {
  color: theme(colors.neutral.300);
  text-shadow: none;
  /*safari and chrome */
  /* firefox */

  transition: color 150ms ease-in;
  /* opera */

}
.box-menu:hover a {
  color: theme(colors.neutral.500);
  text-shadow: 0px 1px 1px theme(colors.white);
}
.content-box .almost-hidden {
  color: theme(colors.neutral.300) !important;
  text-shadow: none;
  /*safari and chrome */
  /* firefox */

  transition: color 150ms ease-in;
  /* opera */

}
.content-box:hover .almost-hidden {
  color: theme(colors.neutral.500) !important;
  text-shadow: 0px 1px 1px theme(colors.white);
}
a.content-box span {
  text-decoration: none !important;
}
a.content-box:hover span {
  text-decoration: none !important;
}
input.filter {
  transition: none 0s ease 0s;
  background: url("search_icon.png") no-repeat scroll 10px center theme(colors.white);
  padding-left: 30px;
  padding-right: 15px;
  border-radius: 100px 100px 100px 100px;
}

.vidpill,
.btn-group .vidpill {
  display: inline-block;
  /* IE7 inline-block hack */
  padding: 4px 10px 4px;
  margin-bottom: 0;
  font-size: theme(fontSize.xs);
  line-height: 18px;
  color: theme(colors.neutral.600);
  text-align: center;
  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.75);
  vertical-align: middle;
  cursor: pointer;
}
.vidpill-active,
.vidpill:hover,
.btn-group .vidpill-active,
.btn-group .vidpill:hover {
  padding: 3px 10px 3px;
  background-color: theme(colors.neutral.100);
  background-image: linear-gradient(to bottom, theme(colors.white), theme(colors.neutral.200));
  background-repeat: repeat-x;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  border: 1px solid theme(colors.neutral.300);
  border-bottom-color: theme(colors.neutral.400);
  border-radius: 100px !important;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
}

/* JQUERY SLIDER */
.ui-slider-horizontal {
  height: 6px !important;
  cursor: pointer !important;
  border: 1px solid theme(colors.neutral.300) !important;
  background: theme(colors.white) !important;
  background-image: linear-gradient(to top, theme(colors.neutral.100) 14%, theme(colors.white) 57%, theme(colors.white) 79%) !important;
  border-radius: 2px !important;
}
.ui-slider-handle {
  height: 20px !important;
  width: 6px !important;
  cursor: pointer !important;
  top: -7px !important;
  outline: 0 !important;
  border-radius: 2px !important;
  border: 1px solid theme(colors.neutral.400) !important;
  background-image: linear-gradient(to top, theme(colors.neutral.200) 14%, theme(colors.neutral.100) 57%, theme(colors.neutral.100) 79%) !important;
}
.menu-info-label {
  margin-left: 5px;
  font-weight: normal;
  background-color: theme(colors.neutral.300);
}
.business-process-list-item {
  margin-top: 6px;
  margin-bottom: 6px;
  padding: 10px;
  border-top: 1px solid white;
  border-left: 1px solid white;
  border-right: 1px solid theme(colors.neutral.100);
  border-bottom: 1px solid theme(colors.neutral.100);
}
.big-list-number {
  padding-right: 10px;
  padding-left: 10px;
  font-size: theme(fontSize.lg);
  font-weight: bold;
  color: theme(colors.neutral.300);
}
a.big-list-number:hover {
	text-decoration: none;
}
.big-list-button {
  font-size: theme(fontSize.base);
  color: theme(colors.neutral.500);
}
.big-list-text {
  padding: 10px;
}
.interview-list {
  padding-top: 20px;
  padding-bottom: 20px;
}
.ui-autocomplete {
  padding: 0px 0px 0px 0px !important;
  z-index: 9999999 !important;
}
.ui-menu .ui-menu,
.ui-menu .ui-menu-item a {
  padding: 0px 0px 0px 0px !important;
  box-sizing: border-box;
}

textarea.form-builder-edit {
  width: 275px;
  height: 40px;
}
.form-builder-edit-divider {
  border-top: 1px solid theme(colors.neutral.200);
  min-width: 285px;
	width: 100%;
  margin-bottom: 15px;
}
.field-position {
  position: relative;
}
.field-position .reorder-handle {
  position: absolute;
  left: -87px;
}
.field-position .reorder-handle .circle-base.content {
  width: 50px;
  height: 50px;
  border: 4px solid theme(colors.neutral.100);
  background-color: white;
  text-align: center;
  color: gray;
}
.field-position .reorder-handle .circle-base.content div {
  font-size: theme(fontSize.base);
  font-weight: bold;
  margin-top: 15px;
}
.field-position .reorder-handle .circle-base.content div i {
  font-weight: normal;
}

/* Email Dialog */
#email-dialog {
  padding: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 22px;
}
#email-dialog input[type=text] {
  width: 450px;

  &.tox-textfield {
    width: 100%;
  }
}
#email-dialog textarea {
  height: 220px;
  width: 450px;

  &.tox-textarea {
    height: auto;
    width: 100%;
  }
}
#email-user-list {
  font-size: theme(fontSize.2xs);
  margin-top: 10px;
  margin-bottom: 10px;
}
/* Change Status Dialog */
#change-status-dialog-content {
  padding-top: 10px;
  padding-bottom: 20px;
  padding-left: 10px;
}
#change-status-user-list {
  font-size: theme(fontSize.2xs);
  margin-top: 10px;
  margin-bottom: 20px;
}
.back-header-button {
  border-bottom: 2px solid theme(colors.neutral.300);
  border-left: 1px solid theme(colors.neutral.300);
  float: right;
}
.left-header-button {
  border-bottom: 2px solid theme(colors.neutral.300);
  border-right: 1px solid theme(colors.neutral.300);
  float: left;
}
.dropdown-header-button {
  border-bottom: 2px solid theme(colors.neutral.300);
  border-right: 1px solid theme(colors.neutral.300);
  float: left;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
}
#applicant-list {
  margin: auto;
}
#applicant-list-container {
  margin: auto;
  text-align: left;
}
#applicant-list-content {
  padding-top: 0px;
  padding-bottom: 30px;
}
#applicant-list-box {
  margin-top: 0px;
  border-left: none;
  margin-bottom: 0px;
}
.nothing-to-display {
  padding: 40px;
}
/* Applicant Answers */
.answers-question-row {
  padding: 10px;
  padding-left: 20px;
  background-color: theme(colors.neutral.100);
  font-weight: bold;
  color: theme(colors.neutral.500);
}
.answers-answer-row {
  padding: 20px;
  font-size: theme(fontSize.xs);
}
.answers-section-row {
  padding: 10px;
  padding-left: 20px;
  font-weight: bold;
}
.answers-answer-row-reference {
  padding-top: 5px;
  padding-bottom: 5px;
}
table.reference-table td {
  padding: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}
table.reference-table td.question {
  font-weight: bold;
  padding-right: 30px;
  padding-left: 0px;
}
div.reference-list {
  padding-top: 5px;
  padding-bottom: 5px;
}
div.reference-list .question {
  font-weight: bold;
  margin-top: 5px;
}
div.reference-list .answer {
  margin-bottom: 10px;
}
/* History */
.activities-page {
  @apply flex flex-col items-center;
}
.history-container {
  padding-bottom: 50px;
}
.history-date {
  @apply mt-px -ml-1 flex flex-col items-center justify-center text-center bg-white w-[80px] h-[80px] xl:w-[100px] xl:h-[100px];
}
.history-date h1 {
  color: theme(colors.neutral.500);
  padding-top: 12px;
  margin-bottom: 0px;
  @apply text-base;
}
.history-date p {
  margin-top: -5px;
  font-weight: 200;
  @apply text-xs;
}
.history-box {
  position: relative;
  width: 48%;
	min-width: 470px;
  border-right: 5px solid theme(colors.neutral.300);
  text-align: left;
  margin-top: 0px;
  padding-bottom: 5px;
  background-color: white;
  line-height: 22px;
  @apply -translate-x-1/2 text-sm;
}
.history-box:hover {
  background-color: theme(colors.neutral.50);
}
.history-box-right {
  border-left: 5px solid theme(colors.neutral.300);
  border-right: none;
  @apply translate-x-1/2 -ml-2;
}
.history-box a {
  text-decoration: none;
}
.history-box a:hover {
  text-decoration: none;
}
.history-stacked {
  cursor: pointer;
  margin-bottom: 20px;
}
.history-stacked:before {
  position: absolute;
  width: 415px;
  height: 5px;
  left: 20px;
  bottom: -8px;
  content: "";
  background-color: theme(colors.neutral.50);
  border: 1px solid theme(colors.neutral.300);
}
.history-stacked:hover {
  background-color: theme(colors.yellow.50);
}
.history-box-left .history-ago {
  text-align: right;
}
.history-ago {
  font-weight: bold;
  color: theme(colors.neutral.700);
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  @apply text-base;
}
.history-item {
  padding-bottom: 10px;
  padding-top: 10px;
}
.history-item-odd {
  background-color: theme(colors.neutral.50);
}
.history-time {
  float: right;
  margin-top: 3px;
  margin-right: 20px;
  color: theme(colors.neutral.400);
  text-align: right;
  @apply text-sm w-20;
}
.history-box-right .history-time {
  float: left;
  margin: 3px 10px 0 20px;
  color: theme(colors.neutral.400);
  @apply w-20;
}
.history-content {
  float: left;
  margin-left: 20px;
  margin-top: 0px;
  @apply text-sm;
}
.history-box-right .history-content {
  float: left;
  margin-left: 20px;
  margin-top: 0px;
}
.history-avatar {
  /* //width: 40px;
  //height: 40px; */
}
.history-description {
  padding-left: 20px;
  word-break: break-word;
  overflow-y: auto;
	@apply w-64 max-w-64 2xl:w-96 2xl:max-w-96;
}
.history-description h5 {
  @apply leading-5;
}
/* Dashboard */
a.dashboard-chart-item-link:hover {
  text-decoration: none;
}
a .dashboard-chart-item,
a:hover .dashboard-chart-item,
a .dashboard-chart-item:hover {
  color: theme(colors.neutral.700);
  text-decoration: none;
}
a .dashboard-chart-item:hover {
  border-left: 5px solid theme(colors.neutral.500);
  text-decoration: none;
}
.dashboard-chart-item {
  border-left: 5px solid theme(colors.neutral.300);
  color: gray;
  @apply ml-4 mb-3 px-2.5 py-2 xl:py-2.5 text-sm;
}
.dashboard-chart-item span {
  float: right;
  color: theme(colors.neutral.400);
}
.dashboard-chart-item-active {
  border-left: 5px solid green;
  color: theme(colors.neutral.700);
}
.vidcruiter-custom-design .dashboard-chart-item-active {
  border-left: 5px solid theme(colors.neutral.700);
}
/* Form Picker */
a.template-picker-item-link:hover {
  text-decoration: none;
}
a .template-picker-item,
a:hover .template-picker-item,
a .template-picker-item:hover {
  color: theme(colors.neutral.700);
  text-decoration: none;
}
a .template-picker-item:hover {
  border-right: 5px solid theme(colors.neutral.500);
  text-decoration: none;
}
.template-picker-item {
  font-size: theme(fontSize.xs);
  padding: 10px;
  padding-top: 7px;
  padding-bottom: 7px;
  margin-left: 0px;
  width: 160px;
  border-right: 5px solid theme(colors.neutral.300);
  margin-bottom: 12px;
  color: gray;
}
.template-picker-item span {
  float: right;
  color: theme(colors.neutral.400);
}
.template-picker-item-active {
  border-right: 5px solid green;
  color: theme(colors.neutral.700);
}
a.attachment-link {
  color: theme(colors.neutral.700);
}
.hover-row:hover a.attachment-link {
  color: theme(colors.sky.600);
}
a.attachment-link:hover {
  color: theme(colors.sky.600);
  text-shadow: none;
}
table.attachment-table td,
table.attachment-table th {
	@apply p-2.5;
}
#hide-attachments {
  padding: 20px;
  padding-bottom: 20px;
  padding-top: 10px;
  text-align: left;
}
.hover-row-color:hover td,
.hover-row-color:hover td p,
.hover-row-color:hover td span {
  color: theme(colors.sky.600);
  text-shadow: none;
}
div.selected,
table tbody tr.selected td,
table tbody tr.selected td p,
table tbody tr.selected:hover td,
table tbody tr.selected:hover td p,
table tbody tr.selected:hover th,
tr.selected:hover td,
.table-striped tbody tr:nth-child(2n+1).selected td,
.table-striped tbody tr:nth-child(2n+1).selected td p {
  background-color: theme(colors.yellow.50);
  color: theme(colors.neutral.700);
  text-shadow: none;
}
.hover-row {
  /*safari and chrome */
  /* firefox */

  transition: color 200ms ease-in;
  /* opera */

}

.hover-row-hidden {
	display:none;
}

.hover-row.-selected {
  .selected-row-hidden {
    display:none !important;
  }
}

.hover-row:hover {
	.hover-row-hidden {
		display:block;
	}
}

.hover-row.-hovered,
.hover-row:hover,
.hover-row:hover p,
.hover-row:hover div,
.table tbody tr:hover td,
.table tbody tr:hover th,
.hover-row:hover td,
.hover-row:hover td p,
.table-striped tbody tr:hover td,
.table-striped tbody tr:nth-child(2n+1):hover td p,
.table-striped tbody tr:nth-child(2n+1):hover th,
.blue-row {
  /*safari and chrome */
  /* firefox */

  transition: color 200ms ease-in;
  /* opera */

  background-color: theme(colors.sky.100);
  color: theme(colors.sky.600);
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
}

.table-nohover tbody tr:hover td,
.table-nohover tbody tr:hover th
 {
  background-color: initial;
  color: initial;
  text-shadow: initial;
}


.dropdown-menu {
  z-index: 999999;
}
dl dt {
  font-weight: bold;
  margin: 0;
  padding: 0;
}
dl dd {
  margin: 0 0 10px;
  padding: 0;
}


.dropdown-submenu:hover > a,
.dropdown-submenu:focus > a {
  color: theme(colors.white);
  text-decoration: none;
  background-color: theme(colors.blue.400);
  /* background-image: linear-gradient(to bottom, theme(colors.sky.600), #0077b3);
  background-repeat: repeat-x; */
}
.dropdown-submenu {
  position: relative;
}
.dropdown-submenu > .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -6px;
  margin-left: -1px;
  border-radius: 0 6px 6px 6px;
}
.dropdown-submenu:hover > .dropdown-menu {
  display: block;
}
.dropup .dropdown-submenu > .dropdown-menu {
  top: auto;
  bottom: 0;
  margin-top: 0;
  margin-bottom: -2px;
  border-radius: 5px 5px 5px 0;
}
.dropdown-submenu > a:after {
  display: block;
  float: right;
  width: 0;
  height: 0;
  margin-top: 5px;
  margin-right: -10px;
  border-color: transparent;
  border-left-color: theme(colors.neutral.300);
  border-style: solid;
  border-width: 5px 0 5px 5px;
  content: " ";
}
.dropdown-submenu:hover > a:after {
  border-left-color: theme(colors.white);
}
.dropdown-submenu.pull-left {
  float: none;
}
.dropdown-submenu.pull-left > .dropdown-menu {
  left: -100%;
  margin-left: 10px;
  border-radius: 6px 0 6px 6px;
}
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  display: none;
  float: left;
  min-width: 160px;
  padding: 2px 0;
  margin: 2px 0 0;
  list-style: none;
  background-color: theme(colors.white);
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  background-clip: padding-box;
}
.dropdown-menu.pull-right {
  right: 0;
  left: auto;
}
.dropdown-menu .divider {
  height: 1px;
  margin: 2px 1px;
  overflow: hidden;
  background-color: theme(colors.neutral.200);
  border-bottom: 1px solid theme(colors.white);
}
.dropdown-menu > li > a, .dropdown-menu > form > li > a {
  clear: both;
  font-weight: normal;
  line-height: 18px;
  color: theme(colors.neutral.700);
  white-space: nowrap;
  border-radius: 1px;
  @apply block mx-0 my-0.5 px-3 py-2 text-sm;
}
.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus,
.dropdown-submenu:hover > a,
.dropdown-submenu:focus > a {
  color: theme(colors.white);
  text-decoration: none;
  background-color: theme(colors.blue.400);
  /* background-image: linear-gradient(to bottom, theme(colors.sky.600), #0077b3);
  background-repeat: repeat-x; */
}
.dropdown-menu > .active > a,
.dropdown-menu > .active > a:hover,
.dropdown-menu > .active > a:focus {
  color: theme(colors.white);
  text-decoration: none;
  background-color: theme(colors.blue.400);
  /* background-image: linear-gradient(to bottom, theme(colors.sky.600), #0077b3);
  background-repeat: repeat-x; */
  outline: 0;
}
.dropdown-menu > .disabled > a,
.dropdown-menu > .disabled > a:hover,
.dropdown-menu > .disabled > a:focus {
  color: theme(colors.neutral.400);
}
.dropdown-menu > .disabled > a:hover,
.dropdown-menu > .disabled > a:focus {
  text-decoration: none;
  cursor: default;
  background-color: transparent;
  background-image: none;
}
.open {
}
.dropdown-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 990;
}
.pull-right > .dropdown-menu {
  right: 0;
  left: auto;
}
.dropup .caret,
.navbar-fixed-bottom .dropdown .caret {
  border-top: 0;
  border-bottom: 4px solid theme(colors.black);
  content: "";
}
.dropup .dropdown-menu,
.navbar-fixed-bottom .dropdown .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-bottom: 1px;
}
.dropdown-submenu {
  position: relative;
}
.dropdown-submenu > .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -6px;
  margin-left: -1px;
  border-radius: 0 6px 6px 6px;
}
.dropdown-submenu:hover > .dropdown-menu {
  display: block;
}
.dropup .dropdown-submenu > .dropdown-menu {
  top: auto;
  bottom: 0;
  margin-top: 0;
  margin-bottom: -2px;
  border-radius: 5px 5px 5px 0;
}
.dropdown-submenu > a:after {
  display: block;
  float: right;
  width: 0;
  height: 0;
  margin-top: 5px;
  margin-right: -10px;
  border-color: transparent;
  border-left-color: theme(colors.neutral.300);
  border-style: solid;
  border-width: 5px 0 5px 5px;
  content: " ";
}
.dropdown-submenu:hover > a:after {
  border-left-color: theme(colors.white);
}
.dropdown-submenu.pull-left {
  float: none;
}
.dropdown-submenu.pull-left > .dropdown-menu {
  left: -100%;
  margin-left: 10px;
  border-radius: 6px 0 6px 6px;
}
.dropdown .dropdown-menu .nav-header {
  padding-right: 15px;
  padding-left: 15px;
}
li.applicants-dropdown > a,
li.applicants-dropdown:hover > a,
li.applicants-dropdown:active > a {
  background: none !important;
}
.navbar .nav > li.applicants-dropdown > .dropdown-menu:before {
  border-bottom: 7px solid rgba(0, 0, 0, 0.2);
  border-left: 7px solid rgba(0, 0, 0, 0);
  border-right: 7px solid rgba(0, 0, 0, 0);
  content: "";
  display: inline-block;
  left: 9px;
  position: absolute;
  top: -7px;
}
.navbar .nav > li.applicants-dropdown > .dropdown-menu:after {
  border-bottom: 6px solid theme(colors.white);
  border-left: 6px solid rgba(0, 0, 0, 0);
  border-right: 6px solid rgba(0, 0, 0, 0);
  content: "";
  display: inline-block;
  left: 10px;
  position: absolute;
  top: -6px;
}
.applicants-dropdown .dropdown-menu {
  top: 43px;
  max-height: 280px;
  width: 350px;
}
.scrollable-menu {
  height: auto;
  max-height: 575px;
  overflow-x: hidden;
}
/* Start of Version 2 (v2) CSS Implementation */
/* Buttons V2 */
.v2 .green-header {
  background-image: url('30_green_header_bg.png');
  border-left: 1px solid theme(colors.sky.100);
  border-right: 1px solid theme(colors.sky.100);
  border-bottom: 1px solid theme(colors.sky.100);
  height: 35px;
  overflow: hidden;
}
.v2 .green-header h2 {
  /*  font-family: serif;
  font-weight: bold;
  font-size: theme(fontSize.base);
*/

  font-size: theme(fontSize.base);
  font-weight: 200;
  text-shadow: none;
  line-height: 33px;
}

#video-currently-recording {
  position: absolute;
  top: 5px;
  left: -45px;
  width: 40px;
}
#video-currently-recording div.record-circle {
  width: 20px;
  height: 20px;
  background-color: red;
  margin: auto;
}
#video-currently-recording p {
  margin-top: 5px;
  color: red;
  font-size: theme(fontSize.base);
  font-weight: 200;
  text-align: center;
}
#new-rating-invitation-form-content {
  padding: 20px;
  padding-top: 0px;
  padding-bottom: 0px;
}
#reinvite-questionnaire-content {
  padding: 20px;
  padding-top: 0px;
  padding-bottom: 0px;
}
#new-rating-invitation-form h6 {
  padding-bottom: 5px;
  padding-top: 15px;
}
#new-rating-invitation-form div.divider {
  border-top: 1px dotted theme(colors.neutral.300);
  width: 100%;
  height: 1px;
}
#new-rating-invitation-form .emails {
  width: 600px;
  min-width: 600px;
  max-width: 600px;
  height: 40px;
  font-size: theme(fontSize.2xs);
}
#new-rating-invitation-form .body {
  width: 600px;
  height: 100px;
}
#new-rating-invitation-form .subject {
  width: 600px;
}
.floating-bar-arrow {
  display: block;
  padding: 13px;
  padding-right: 10px !important;
  padding-left: 10px !important;
  width: 16px;
}
.floating-bar-arrow:hover, .floating-bar-arrow.active {
  background-color: theme(colors.neutral.100);
}
a.menu-image {
  display: block;
  background-image: url('menu_images.png');
  height: 18px;
  width: 32px;
  margin: 5px;
  background-repeat: no-repeat;
  background-position: 0px -36px;
  padding: 0px;
}
a.menu-image:hover {
  background-position: 0px -54px;
}
a.menu-image-hide {
  display: block;
  background-image: url('menu_images.png');
  height: 18px;
  width: 32px;
  margin: 5px;
  background-repeat: no-repeat;
  background-position: 0px 0px;
  padding: 0px;
}
a.menu-image-hide:hover {
  background-position: 0px -18px;
}
.ratings-person-name {
  margin-bottom: 2px;
  font-size: theme(fontSize.base);
  font-weight: normal;
}
.ratings-panel-header-selected .ratings-person-name {
  font-size: theme(fontSize.lg);
}
.ratings-person-avatar {
  width: 35px;
  height: 40px;
}
.ratings-person-avatar img {
  width: 40px;
  height: 40px;
  position: initial;
}
.ratings-panel-header-selected .ratings-person-avatar {
  width: 35px;
  height: 45px;
}
.ratings-panel-header-selected .ratings-person-avatar img {
  width: 60px;
  height: 60px;
  position: initial;
}
.progress-vidcruiter .bar,
.progress .bar-vidcruiter {
  background-color: theme(colors.green.500);
  background-repeat: repeat-x;
  background: theme(colors.white);
  background-image: linear-gradient(to bottom, theme(colors.neutral.50), theme(colors.white));
  border: 1px solid theme(colors.neutral.300);
}
.person-extra-link div {
  font-weight: bold;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 0px;
  top: -22px;
  left: 30px;
}
.person-extra-link div i {
  margin-top: 7px;
  color: theme(colors.neutral.500);
}
.v3-datepicker .ui-datepicker-header {
  border: 1px solid red;
}


/* Vidcruiter 30 */
.vid-panel {
  background-color: white;
}
#vid-links-bar {
  height: 30px;
  background-color: theme(colors.neutral.900);
  width: 100%;
  color: theme(colors.neutral.400);
	@apply text-sm;
}
#vid-links-bar a.links-bar-button {
  display: flex;
  align-items: center;
  color: theme(colors.neutral.400);
  font-size: theme(fontSize.xs);
  white-space: nowrap;
}
#vid-links-bar a.links-bar-button:hover {
  text-decoration: none;
  color: white;
}
#vid-advanced-search-button {
  height: 27px;
  padding-left: 9px;
  padding-right: 9px;
  background-repeat: no-repeat;
  position: absolute;
  right: 2px;
  top: 3px;
  border-radius: 0px 4px 4px 0px;
  color: theme(colors.neutral.500);
  cursor: pointer;
}
#vid-advanced-search-button:hover {
  color: theme(colors.neutral.800);
  background-color: theme(colors.neutral.200);
}
/* V3 */
.v2 h6 {
  font-family: serif;
  color: theme(colors.neutral.400);
  font-size: theme(fontSize.base);
  font-weight: bold;
  text-transform: none;
}
/* Hoverable Row V2 */
.hover-v2-white {
  border: 1px solid white;
  border-bottom: 1px dotted theme(colors.neutral.300);
}
.hover-v2:hover {
  border: 1px solid theme(colors.blue.200);
  border-bottom: 1px solid theme(colors.blue.200);
}
.hover-v2:hover,
.hover-v2:hover p,
.hover-v2:hover div,
.hover-v2:hover td,
.hover-v2:hover td p {
  background-color: theme(colors.sky.100);
  color: theme(colors.sky.600);
}
input[type="radio"],
input[type="checkbox"] {
  margin: 0px 0 2px 0;
}
.input-prepend.input-append input.current-page-position,
.input-prepend.input-append select.current-page-position,
.input-prepend.input-append .uneditable-input {
  width: 65px;
  min-height: 21px;
  color: theme(colors.neutral.600);
  font-weight: normal;
  font-size: theme(fontSize.xs);
  @apply py-1 xl:py-1.5;
}
#history-settings-bar {
  z-index: 999;
  @apply sticky top-0 px-0 py-3 text-center bg-white shadow;
}
#applicant-history-settings-bar {
  z-index: 999;
}
.sidebar-toggler {
  position: absolute;
  /* //background-image: url('live_interview_sidebar_toggle.png');
  //background-repeat: no-repeat; */
  width: 15px;
  text-align: center;
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: theme(fontSize.2xs);
  top: 50%;
  margin-top: -25px;
  left: -15px;
  background-color: theme(colors.neutral.100);
  color: theme(colors.neutral.500);
  z-index: 9999999;
}

.sidebar-toggler:hover {
  background-color: theme(colors.neutral.200);
  width: 20px;
  border-color: theme(colors.neutral.200);
  color: theme(colors.neutral.700);
}

.sidebar-toggler-left {
  border-right: none;
  background-color: theme(colors.white);
  background-position: -21px 0px;
  border-radius: 3px 0px 0px 3px;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.3);

}
.sidebar-toggler-left:hover {
  background-position: -21px -49px;
  left: -20px;
}
.sidebar-toggler-right {
  background-position: 0px 0px;
  border-left: none;
  left: 0px;
  border-radius: 0px 3px 3px 0px;
}
.sidebar-toggler-right:hover {
  background-position: 0px -49px;
}
.circle-status {
  background-color: theme(colors.neutral.300);
  display: inline-block;
  width: 17px;
  height: 17px;
  line-height: 17px;
  font-size: 8px;
  text-align: center;
  color: theme(colors.white);
}
.circle-status-small {
   width: 10px; height: 10px; line-height: 10px;
}
.circle-status-green {
  background-color: theme(colors.green.500);
}
.circle-status-red {
  background-color: theme(colors.red.400);
}
.circle-status-yellow {
/* //  background-color: #FBEED5; */
  background-color: theme(colors.amber.300);
}

.live-interview-person-entry td {
  border-bottom: 1px solid theme(colors.neutral.100);
}

.live-interview-person-entry.-applicant td {
	background-color: theme(colors.yellow.100);
}

.live-interview-sidebar-header-link {
  display: inline-block;
  float: left;
  font-size: 22px;
  padding: 3px;
  color: theme(colors.neutral.400);
}
.live-interview-sidebar-header-link:hover {
  background-color: white;
  text-decoration: none;
  color: theme(colors.neutral.700);
}
.live-interview-note {
  border-bottom: 1px solid theme(colors.neutral.100);
}
.live-interview-note .username {
  color: theme(colors.neutral.400);
  font-size: theme(fontSize.xs);
  text-transform: uppercase;
  margin-bottom: 0px;
  margin-left: 10px;
  margin-top: 7px;
}
.live-interview-note .message {
  word-wrap: break-word; /* old name */
  overflow-wrap: break-word;
  max-width: 250px;
  color: theme(colors.neutral.800);
  font-size: theme(fontSize.sm);
  font-weight: bold;
  margin-left: 10px;
  margin-bottom: 7px;
}
.live-interview-note .private-note {
  color: theme(colors.red.950);
  background-color: theme(colors.red.50);
  padding: 3px;
  font-weight: bold;
}
.live-interview-note .file {
  font-size: theme(fontSize.2xs);
  padding-left: 10px;
}
.live-interview-note .timestamp {
  float: right;
  font-size: theme(fontSize.2xs);
  margin-top: 6px;
  margin-right: 10px;
  color: theme(colors.neutral.400);
  text-align: right;
}
.vidlive-chat-submit {
  width: 50px;
  font-size: theme(fontSize.xl);
  line-height: 32px;
  color: theme(colors.neutral.500);
  padding-top: 0px;
  padding-bottom: 0px;
  border-radius: 5px;
  margin-bottom: 0px;
}

.vidlive-chat-text {
width: 290px;
  box-sizing: border-box;
height: 45px;
resize: none;
font-size: theme(fontSize.xs);
margin-bottom: 0px;
  margin-left: 5px;
border: 1px solid theme(colors.neutral.400);
border-bottom: 2px solid theme(colors.neutral.400);
border-left: 2px solid theme(colors.neutral.300);
border-radius: 5px;
  padding-right: 50px;
}
.live-interview-message-content {
padding: 10px; padding-top: 2px; padding-bottom: 2px;
max-width: 840px;
}

/* Previewer */
#previewer-container {
  background-image: url('transparent_black.png');
  /* //min-height: 300px;
  //min-width: 600px; */
  position: fixed;
  top: 0px;
  font-size: theme(fontSize.sm);
  /* margins in pixels */

  bottom: 0px;
  /* could also use a percent */

  left: 0px;
  right: 0px;
  z-index: 999991;
  /* //color: white; */
}
.pane {
  display: none;
  /* will appear when layout inits */

}
#viewerBoxContainer {
  height: 100%; width: 960px; margin: auto;
}
#contentContainer {
  position: relative;
  background-color: theme(colors.white);
  height: inherit;
  width: inherit;
  top: 0px;
}
@media (max-width : 960px) {
  #viewerBoxContainer {
    width: 100%;
  }
}
/* End Previewer */
#back-top {
  position: fixed;
  bottom: 0px;
  background-color: white;
  right: 20px;
  background-image: url('backtotop.jpg');
  background-repeat: no-repeat;
  width: 33px;
  height: 31px;
  border: 1px solid theme(colors.neutral.300);
  border-bottom: none;
  box-shadow: inset 0px -1px 1px 0px rgba(0, 0, 0, 0.3);
  display: none;
  z-index: 9999999999;
  opacity: 0.6;
  border-radius: 5px 5px 0px 0px;
}
#back-top:hover {
  height: 33px;
  opacity: 1;
}
.dropdown-menu-right-arrow:before {
  border-bottom: 7px solid rgba(0, 0, 0, 0.2);
  border-left: 7px solid rgba(0, 0, 0, 0);
  border-right: 7px solid rgba(0, 0, 0, 0);
  content: "";
  display: inline-block;
  left: 9px;
  position: absolute;
  top: -7px;
}
.dropdown-menu-right-arrow:after {
  border-bottom: 6px solid theme(colors.white);
  border-left: 6px solid rgba(0, 0, 0, 0);
  border-right: 6px solid rgba(0, 0, 0, 0);
  content: "";
  display: inline-block;
  left: 10px;
  position: absolute;
  top: -6px;
}
.dropdown-menu-right-arrow:before {
  left: auto;
  right: 12px;
}
.dropdown-menu-right-arrow:after {
  left: auto;
  right: 13px;
}
.dropdown-menu-left-arrow:after {
  left: 13px;
  right: auto;
}
ul.striped li:nth-child(odd) {
  background-color: theme(colors.neutral.100);
}
.help-tab {
  padding: 7px;
  padding-bottom: 6px;
  padding-top: 6px;
  background-color: theme(colors.neutral.100);
  position: absolute;
  left: 20px !important;
  bottom: 0px !important;
  font-size: theme(fontSize.lg);
  text-decoration: none;
  color: theme(colors.green.600);
  box-shadow: 1px 1px 4px theme(colors.neutral.400);
}
.help-tab:hover {
  color: white;
  text-decoration: none;
  background-color: theme(colors.green.600);
}
.help-tab:active {
  position: absolute;
  bottom: 0px !important;
  left: 20px !important;
}
.help-tab span {
  display: inline;
  color: theme(colors.neutral.500);
  font-size: theme(fontSize.sm);
  padding-right: 5px;
  padding-left: 5px;
}
.help-tab:hover span {
  color: white;
}
.live-interview-tag {
  padding: 10px;
  padding-left: 15px;
  padding-right: 15px;
  border-bottom: 1px solid theme(colors.neutral.100);
}
.live-interview-tag .name {
  font-size: theme(fontSize.base);
  font-weight: bold;
  max-width: 230px;
  overflow-wrap: break-word;
}
.rating-system-sidebar-list {
  list-style-type: none;
  text-align: left;
  margin-left: 0px;
  margin-top: 20px;
}
.rating-system-sidebar-list li {
  width: 100%;
  padding: 10px;
  border: 1px solid theme(colors.neutral.200);
  border-bottom: 2px solid theme(colors.neutral.200);
  text-decoration: none !important;
  margin-bottom: 5px;
  @apply box-border text-sm;
}
.rating-system-sidebar-list a:hover {
  text-decoration: none;
}
.rating-system-sidebar-list li:hover {
  border: 1px solid theme(colors.neutral.300);
  border-bottom: 2px solid theme(colors.neutral.300);
  @apply bg-sky-50;
}
.rating-system-sidebar-list li.active {
  border: 1px solid theme(colors.neutral.300);
  border-bottom: 4px solid theme(colors.green.500);
}
.rating-review-check-container .check {
  padding: 5px;
}
.rating-review-check-container .rater {
  padding: 5px;
  text-align: left;
  font-size: theme(fontSize.xs);
  cursor: pointer;
}
.rating-review-check-container-active .check {
  padding: 5px;
  background-color: theme(colors.vidgreen);
}
.rating-review-check-container-active .rater {
  padding: 5px;
  text-align: left;
  background-color: theme(colors.green.100);
  color: theme(colors.green.900);
  padding-left: 10px;
}

.hidden-validation-field {
	width: 1px !important;
	height: 1px !important;
	background-color: transparent !important;
    border: 0px !important;
    outline: none !important;
    box-shadow: none !important;
    color:transparent !important;
    cursor:default !important;
}

.hidden-validation-field-child {
    position: absolute; left: -999999px;
}

.dropdown-menu li a span.dropdown-info {
	color: theme(colors.neutral.500); display: block; font-size: theme(fontSize.2xs); font-style: italic;
}

.dropdown-menu li a:hover span.dropdown-info {
	color: theme(colors.white);
}

.live-interview-layout .fancybox-overlay {
  z-index: 1000000 !important;
  background-color: rgba(24, 24, 27, 0.64);
}

.live-interview-layout .fancybox-opened {
  z-index: 10000001;
}
